import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import Autocomplete from "@mui/material/Autocomplete";
import Divider from "@mui/material/Divider";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { isEqual } from "lodash";
import * as React from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import {
  useAddDomainMutation,
  useGetDomainsQuery,
  api,
  useGetUserBrandsQuery,
} from "../../store/api";
import { setDomainParams, setDomainsFetching } from "../../store/app";
import CustomDatePicker from "./DatePicker";
import styled from "styled-components/macro";
import dayjs from "dayjs";
import { COMPLAINT_STATUS_TYPES } from "../../utils/labels";
import { matchSorter } from "match-sorter";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const useStyles = makeStyles({
  dateclass: {
    border: "1px solid #a5afc3",
  },
  expandmoreiconcolor: {
    color: "#a5afc3",
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "#a5afc3 !important",
  },

  customOutline: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#a5afc3",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#a5afc3",
    },
  },
});

const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.custom.primary};
`;

const DomainFilter = () => {
  const { user } = useAuth();
  const params = useSelector((state) => state.app.domainParams);
  const router = useLocation();
  const location = router.pathname;
  const [search, setSearch] = React.useState("");
  const [startDate, setStartDate] = React.useState(dayjs(params.start));
  const [endDate, setEndDate] = React.useState(dayjs(params.end));
  const [dateField, setDateField] = React.useState("Created");
  const [complaintStatus, setComplaintStatus] = React.useState([]);
  const dispatch = useDispatch();
  const [brands, setBrands] = React.useState([]);
  const [allBrands, setAllBrands] = React.useState([]);
  const filterOptions = (options, { inputValue }) =>
    matchSorter(options, inputValue);
  const [addDomain] = useAddDomainMutation();
  const [loading, setLoading] = React.useState(false);
  const [brandOpen, setBrandOpen] = React.useState(false);
  const [invalidDomain, setInvalidDomain] = React.useState(false);
  const domainRegex = new RegExp(
    "^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:.[a-zA-Z]{2,})+$"
  );

  const { data: all_brands } = useGetUserBrandsQuery(
    user?.auth ? undefined : skipToken
  );

  const updateFilters = (type) => {
    if (type === "search") {
      if (isEqual(search, params.search)) {
        return false;
      } else {
        dispatch(
          setDomainParams({
            ...params,
            field: "name",
            contains: search,
          })
        );
      }
    } else if (type === "clear") {
      setSearch("");
      setStartDate(dayjs().startOf("year").valueOf());
      setEndDate(dayjs().endOf("day").valueOf());
      setBrands([]);
      dispatch(
        setDomainParams({
          ...params,
          field: undefined,
          contains: undefined,
        })
      );
    }
  };

  React.useEffect(() => {
    api.util.invalidateTags("Domains");
  }, [params]);

  const { data: domains } = useGetDomainsQuery(
    user && location === "/domains" && params.sortBy !== "score"
      ? params
      : skipToken
  );

  React.useEffect(() => {
    const brandNames = all_brands?.map((brand) => brand.name).sort();
    const uniqueBrands = [...new Set(brandNames)];
    setAllBrands(uniqueBrands);
    setBrands(uniqueBrands);
    // eslint-disable-next-line
  }, [user, all_brands]);

  const validateDomain = (value) => {
    try {
      let url = new URL(value);
      return url.hostname;
    } catch (e) {
      // ignore
    } finally {
      setInvalidDomain(false);
    }
    if (domainRegex.test(value) && value.includes(".")) {
      setInvalidDomain(false);
    } else {
      setInvalidDomain(true);
    }
    return value;
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 50,
        left: "0px !important",
      },
    },
  };

  const handleDateChange = (date, type) => {
    if (type === "start") {
      setStartDate(date);
      dispatch(
        setDomainParams({
          ...params,
          start: date.valueOf(),
          end: params.end,
        })
      );
    } else {
      setEndDate(date);
      dispatch(
        setDomainParams({
          ...params,
          start: params.start,
          end: date.valueOf(),
        })
      );
    }
  };

  const handleBrandChange = (value) => {
    if (value.includes("0all")) {
      if (value.length === allBrands.length + 1) {
        setBrands([]);
      } else {
        setBrands(allBrands);
      }
    } else {
      setBrands(typeof value === "string" ? value.split(",") : value);
    }
    if (!brandOpen) {
      dispatch(
        setDomainParams({
          ...params,
          brands: value,
        })
      );
    }
  };

  React.useEffect(() => {
    if (brandOpen === false) {
      dispatch(
        setDomainParams({
          ...params,
          brands: brands,
        })
      );
    }
    // eslint-disable-next-line
  }, [brandOpen, brands]);

  const classes = useStyles();

  return (
    <Stack
      spacing={3}
      sx={{
        justifyContent: "center",
        alignItems: "flex-end",
        mb: 5,
      }}
    >
      <StyledButton variant="text" onClick={() => updateFilters("clear")}>
        Clear Filter
      </StyledButton>
      <FormControl
        classes={{ root: classes.customOutline }}
        variant="outlined"
        fullWidth
      >
        <CustomDatePicker
          date={startDate}
          setDate={handleDateChange}
          dateField={dateField}
          setDateField={setDateField}
          fields={["Created", "Filing", "Takedown"]}
        />
      </FormControl>
      <FormControl
        classes={{ root: classes.customOutline }}
        variant="outlined"
        fullWidth
      >
        <CustomDatePicker
          date={endDate}
          minDate={startDate}
          setDate={handleDateChange}
          dateField={dateField}
          setDateField={setDateField}
          label="End Date"
        />
      </FormControl>
      <FormControl
        fullWidth
        variant="outlined"
        classes={{ root: classes.customOutline }}
      >
        <InputLabel id="complaint-status-multiple-checkbox-label">
          Filing Status
        </InputLabel>
        <Select
          labelId="complaint-status-multiple-checkbox-label"
          id="complaint-status-multiple-chip"
          multiple
          value={complaintStatus}
          onChange={(e) => {
            setComplaintStatus(e.target.value);
          }}
          onClose={() => {
            dispatch(
              setDomainParams({
                ...params,
                complaintStatus: complaintStatus,
              })
            );
          }}
          style={{
            placeholder: {
              color: "purple",
              fontSize: 12,
              fontWeight: "bold",
            },
          }}
          input={<OutlinedInput label=" Filing Status " />}
          renderValue={(selected) => {
            return COMPLAINT_STATUS_TYPES.filter((o) =>
              selected.includes(o.value)
            )
              .map((o) => o.text)
              .join(", ");
          }}
          MenuProps={MenuProps}
        >
          {COMPLAINT_STATUS_TYPES.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              <Checkbox checked={complaintStatus.indexOf(option.value) > -1} />
              <ListItemText primary={option.text} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        fullWidth
        sx={{ m: 1, pl: 0, pt: 0 }}
        variant="outlined"
        classes={{ root: classes.customOutline }}
      >
        <Autocomplete
          multiple
          limitTags={2}
          value={brands}
          filterOptions={filterOptions}
          onChange={(_event, newValue) => {
            handleBrandChange(newValue.length === 0 ? [] : newValue.join(","));
          }}
          onClose={() => setBrandOpen(false)}
          onOpen={() => setBrandOpen(true)}
          id="brands-select"
          options={allBrands ? ["0all", ...allBrands] : []}
          disableCloseOnSelect
          renderOption={(props, option, { selected }) => (
            <div key={option}>
              <MenuItem {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={
                    option === "0all"
                      ? brands.length > 0 && brands.length === allBrands.length
                      : selected
                  }
                  indeterminate={
                    option === "0all"
                      ? brands.length > 0 && brands.length < allBrands.length
                      : false
                  }
                />
                {option === "0all" ? "Select All" : option}
              </MenuItem>
              {option === "0all" && <Divider />}
            </div>
          )}
          renderInput={(params) => <TextField {...params} label="Brand" />}
        />
      </FormControl>
      <TextField
        fullWidth
        id="outlined-search"
        value={search}
        size="small"
        error={invalidDomain}
        helperText={
          invalidDomain
            ? "Invalid domain"
            : domains?.list.map((d) => d.name).includes(search)
            ? "Domain exists"
            : ""
        }
        label="Search"
        onChange={(e) =>
          setSearch(validateDomain(e.target.value.toLowerCase()))
        }
        variant="outlined"
        onKeyPress={(ev) => {
          if (ev.key === "Enter") {
            updateFilters("search");
            ev.preventDefault();
          }
        }}
      />
      <LoadingButton
        fullWidth
        loading={loading}
        disabled={
          invalidDomain ||
          !search ||
          domains?.list.map((d) => d.name).includes(search)
        }
        onClick={() => {
          setLoading(true);
          addDomain({ name: search }).then(() => {
            setTimeout(function () {
              dispatch(setDomainsFetching(true));
              updateFilters("search");
              setLoading(false);
            }, 5000);
          });
        }}
        variant="outlined"
      >
        Add Domain
      </LoadingButton>
    </Stack>
  );
};

export default DomainFilter;
