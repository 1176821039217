export const INFRINGEMENT_TYPES = [
  { text: "Copyright", value: "CR" },
  { text: "Trademark", value: "TM" },
  { text: "Design", value: "DE" },
  { text: "Counterfeit", value: "CFT" },
  { text: "Cybersquatting", value: "CS" },
  { text: "Unauthorized Sale", value: "US" },
];

export const PLATFORM_TYPE = [
  { text: "Website", value: "WEBSITE" },
  { text: "Social Media", value: "SOCIAL_MEDIA" },
  { text: "Marketplace", value: "MARKETPLACE" },
  { text: "Mobile App", value: "MOBILE_APP" },
  { text: "NFT", value: "NFT" },
  { text: "Metaverse", value: "META" },
];

export const LISTING_STATUS = [
  { text: "Active", value: "ACTIVE" },
  { text: "Inactive", value: "INACTIVE" },
  { text: "Violation Removed", value: "VIOLATION_REMOVED" },
  { text: "Delisted", value: "DELISTED" },
];

export const TESTPURCHASE_STATUS = [
  { text: "Assigned", value: "ASSIGNED" },
  { text: "Canceled", value: "CANCELED" },
  { text: "Complete", value: "COMPLETE" },
  { text: "New", value: "NEW" },
];

export const INVESTIGATION_ORIGIN = [
  { text: "Online Lead", value: "ONLINE_LEAD" },
  { text: "AACS Enforcement Action", value: "AACS_ENFORCEMENT_ACTION" },
  { text: "Investigation", value: "INVESTIGATION" },
];

export const INFRINGEMENT_REASONS = [
  { value: "COUNTERFEIT", toi: "CFT", text: "Counterfeit" },
  { value: "UNAUTHORIZED_MARK", toi: "TM", text: "Unauthorized mark" },
  { value: "UNAUTHORIZED_LOGO", toi: "TM", text: "Unauthorized logo" },
  { value: "UNAUTHORIZED_DEVICE", toi: "TM", text: "Unauthorized device" },
  { value: "REG_COPYRIGHT_MATCH", toi: "CR", text: "Registered copyright use" },
  { value: "COPYRIGHT_MATCH", toi: "CR", text: "Use of copyright image" },
  {
    value: "DESIGN_INFRINGEMENT",
    toi: "DE",
    text: "Design patent infringement",
  },
  {
    value: "UTILITY_INFRINGEMENT",
    toi: "DE",
    text: "Utility patent infringement",
  },
  { value: "LOGO_CHARACTER_MATCH", toi: "CR", text: "Use of logo/character" },
  { value: "PACK_SHOT_IMAGE", toi: "CR", text: "Use of official packshots" },
  {
    value: "CREATIVE_VISUAL_IMAGE",
    toi: "CR",
    text: "Use of Creative Visuals",
  },
  { value: "NO_RISK", toi: "US", text: "No Risk" },
  { value: "TESTER_SALE", toi: "US", text: "Tester Sale" },
  { value: "SAMPLE_SALE", toi: "US", text: "Sample Sale" },
  { value: "GREY_MARKET", toi: "US", text: "Grey Market" },
  {
    value: "SELECTIVE_INTEGRATED_DISTRIBUTION",
    toi: "US",
    text: "Selective/Integrated Distribution",
  },
];

export const COMPLAINT_DESCRIPTION_TEMPLATES = [
  { value: "CR", toi: "CR", text: "Unauthorized use of registered copyright" },
  { value: "CI", toi: "CR", text: "Unauthorized use of copyright image" },
  { value: "CFT", toi: "CFT", text: "Variation in product features" },
  { value: "TM", toi: "TM", text: "Unauthorized trademark use" },
  { value: "DE", toi: "DE", text: "Design patent infringement" },
  { value: "US", toi: "US", text: "Unauthorized sale" },
];

export const STATUS_TYPES = [
  { text: "Active", value: "ACTIVE" },
  { text: "Inactive", value: "INACTIVE" },
  { text: "Violation Removed", value: "VIOLATION_REMOVED" },
  { text: "Delisted", value: "DELISTED" },
];

export const COMPLAINT_STATUS_TYPES = [
  { text: "New", value: "NEW" },
  { text: "Filed", value: "FILED" },
  { text: "Removed", value: "PROCESSED" },
  { text: "Withdrawn", value: "WITHDRAWN" },
  { text: "Draft", value: "DRAFT" },
  { text: "On Hold", value: "HOLD" },
  { text: "Reinstated", value: "REINSTATED" },
];

export const STATE_TYPES = [
  { text: "New", value: "NEW" },
  { text: "Infringing", value: "INFRINGING" },
  { text: "Irrelevant", value: "IRRELEVANT" },
  { text: "Suspect", value: "SUSPECT" },
  { text: "Authorized Seller", value: "AUTHORIZED_SELLER" },
  { text: "Lookalike", value: "LOOKALIKE" },
  { text: "Reviewed", value: "REVIEWED" },
  { text: "Reinstated", value: "REINSTATED" },
  { text: "Genuine", value: "GENUINE" },
  { text: "Draft", value: "DRAFT" },
  { text: "Hold", value: "SELLER_HOLD" },
];

export const getIprLabel = (ipr) => {
  if (!ipr) {
    return "UNNOWN";
  }
  if (typeof ipr == "string") {
    return ipr; // TODO remove after migration
  }
  const { name, classes, registration, type, jurisdictions } = ipr;
  const classList = (classes || []).map(
    (c1) => CLASSES.find((c2) => c1.trim() === c2.value).number
  );
  classList.sort();
  const country = jurisdictions?.length > 0 ? jurisdictions[0] : "UNKNOWN";
  return `${name} (${registration}_${
    type ? `${type}_` : ""
  }${country}_${classList.join(",")})`;
};

export const getStatus = (value) => {
  let status = STATUS_TYPES.find((type) => type.value === value);
  return status ? status.text : "Unknown";
};

export const getComplaintStatus = (value) => {
  let status = COMPLAINT_STATUS_TYPES.find((type) => type.value === value);
  return status ? status.text : "Unknown";
};
export const getState = (value) => {
  let state = STATE_TYPES.find((type) => type.value === value);
  return state ? state.text : "Unknown";
};

export const getToi = (value) => {
  let state = INFRINGEMENT_TYPES.find((type) => type.value === value);
  return state ? state.text : "";
};

export const getReason = (value) => {
  let status = INFRINGEMENT_REASONS.find((type) => type.value === value);
  return status ? status.text : "Unknown";
};

export const COPYRIGHT_REASONS = [
  { text: "Unauthorized Image Use", value: "COPYRIGHT_MATCH" },
  { text: "Use of Official Packshots", value: "PACK_SHOT_IMAGE" },
  { text: "Use of Creative Visuals", value: "CREATIVE_VISUAL_IMAGE" },
  { text: "Registered Copyright Use", value: "REG_COPYRIGHT_MATCH" },
  { text: "Use of Logo/Character", value: "LOGO_CHARACTER_MATCH" },
];

export const TRADEMARK_REASONS = [
  { text: "Counterfeit", value: "COUNTERFEIT" },
  { text: "Unauthorized use of mark", value: "UNAUTHORIZED_MARK" },
  { text: "Unauthorized use of logo", value: "UNAUTHORIZED_LOGO" },
];

export const US_REASONS = [
  { text: "No Risk", value: "NO_RISK" },
  { text: "Grey Market", value: "GREY_MARKET" },
  {
    text: "Selective Integrated Distribution",
    value: "SELECTIVE_INTEGRATED_DISTRIBUTION",
  },
];

export const CLASSES = [
  { text: "Chemicals", value: "CHEMICALS", number: 1 },
  { text: "Paints", value: "PAINTS", number: 2 },
  {
    text: "Cosmetics and Cleaning Preparations",
    value: "COSMETICS",
    number: 3,
  },
  { text: "Lubricants and Fuels", value: "FUELS", number: 4 },
  { text: "Pharmaceuticals", value: "PHARMACEUTICALS", number: 5 },
  { text: "Metal Goods", value: "METALS", number: 6 },
  { text: "Machinery", value: "MACHINERY", number: 7 },
  { text: "Hand Tools", value: "HAND_TOOLS", number: 8 },
  {
    text: "Electrical and Scientific Apparatus",
    value: "ELECTRICAL",
    number: 9,
  },
  { text: "Medical Apparatus", value: "MEDICAL", number: 10 },
  {
    text: "Environmental Control Apparatus",
    value: "ENVIRONMENTAL",
    number: 11,
  },
  { text: "Vehicles", value: "VEHICLES", number: 12 },
  { text: "Firearms", value: "FIREARMS", number: 13 },
  { text: "Jewelry", value: "JEWELRY", number: 14 },
  { text: "Musical Instruments", value: "MUSICAL", number: 15 },
  { text: "Paper goods and Printed Matter", value: "PAPER", number: 16 },
  { text: "Rubber Goods", value: "RUBBER", number: 17 },
  { text: "Leather Goods", value: "LEATHER", number: 18 },
  { text: "Nonmetallic Building Materials", value: "BUILDING", number: 19 },
  {
    text: "Furniture and Articles not Otherwise Classified",
    value: "FURNITURE",
    number: 20,
  },
  { text: "Housewares and Glass", value: "HOUSEWARES", number: 21 },
  { text: "Cordage and Fibers", value: "CORDAGE", number: 22 },
  { text: "Yarns and Threads", value: "YARN", number: 23 },
  { text: "Fabrics", value: "FABRICS", number: 24 },
  { text: "Clothing", value: "CLOTHING", number: 25 },
  { text: "Fancy Goods", value: "FANCY", number: 26 },
  { text: "Floor Coverings", value: "FLOOR", number: 27 },
  { text: "Toys and Sporting Goods", value: "TOYS", number: 28 },
  { text: "Meats and Processed Foods", value: "MEATS", number: 29 },
  { text: "Staple Foods", value: "FOOD", number: 30 },
  { text: "Natural Agricultural Products", value: "AGRICULTURE", number: 31 },
  { text: "Light Beverages", value: "BEVERAGES", number: 32 },
  { text: "Wine and Spirits", value: "SPIRITS", number: 33 },
  { text: "Smokers' Articles", value: "SMOKERS", number: 34 },
  { text: "Advertising Services", value: "ADVERTISING", number: 35 },
  { text: "Financial Services", value: "FINANCIAL", number: 36 },
  { text: "Construction Services", value: "CONSTRUCTION", number: 37 },
  { text: "Telecommunication Services", value: "TELECOM", number: 38 },
  { text: "Travel and Transport Services", value: "TRANSPORT", number: 39 },
  { text: "Material Treatment Services", value: "TREATMENT", number: 40 },
  { text: "Education Services", value: "EDUCATION", number: 41 },
  {
    text: "Scientific and Technological Services",
    value: "TECHNOLOGY",
    number: 42,
  },
  { text: "Food and Beverage Services", value: "FOOD_BEVERAGE", number: 43 },
  { text: "Medical Services", value: "MEDICAL_SERVICES", number: 44 },
  { text: "Legal and Security Services", value: "LEGAL", number: 45 },
];

export const getFilterString = (params) => {
  let result = [];
  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      if (value.length > 0) {
        result.push(`${key}: ${value.join(", ")}`);
      }
    } else if (key === "start" || key === "end") {
      result.push(
        `${key}: ${new Intl.DateTimeFormat("en-US").format(new Date(value))}`
      );
    } else if (
      value &&
      key !== "order" &&
      key !== "filed" &&
      key !== "dateField" &&
      key !== "sortBy" &&
      key !== "limit"
    ) {
      result.push(`${key}: ${value}`);
    }
  }
  return result.join(", ");
};
