import ShieldIcon from "@mui/icons-material/Shield";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import BadShieldIcon from "@mui/icons-material/GppMaybe";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import IconButton from "@mui/material/IconButton";
import { Typography, Button, Divider, Icon } from "@mui/material";
import { getStatus, getReason, getState, getToi } from "../../../utils/labels";
import LoadingButton from "@mui/lab/LoadingButton";
import OpenApiLogo from "./openai.svg";
import { styled } from "@mui/material/styles";
import { hasRole } from "../../../utils/auth";
import * as React from "react";

export const ExpandMore = styled((props) => {
  // eslint-disable-next-line
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const getPriceColor = (score) => {
  if (score < 0 || score >= 1.5 || score <= 0.5) {
    return "red";
  } else if (score >= 1.25 || score <= 0.75) {
    return "orange";
  } else {
    return "inherit";
  }
};

export const OpenAILogoIcon = (ml) => (
  <Icon sx={{ height: "24px", marginLeft: ml || -6, marginTop: -1 }}>
    <img src={OpenApiLogo} alt="open ai" height={28} width={20} />
  </Icon>
);

export const TextAnalysis = (props) => {
  const { data, listing, isLoading, user, analyzeListingText, error } = props;
  if (data) {
    return (
      <React.Fragment>
        <Divider variant="fullWidth" sx={{ mt: 5, mb: 5, opacity: 0.6 }}>
          Listing Text Analysis
        </Divider>
        {data.analysis && (
          <Typography sx={{ whiteSpace: "pre-wrap" }}>
            {data.analysis.replaceAll("**", "")}
          </Typography>
        )}
        {data.assessment && (
          <Typography>{`Risk: ${data.assessment}`}</Typography>
        )}
        {data.observations && (
          <div>
            <Typography>Observations:</Typography>
            <ol>
              {data.observations.map((o, index) => (
                <li key={index}>{o}</li>
              ))}
            </ol>
          </div>
        )}
      </React.Fragment>
    );
  } else {
    return (
      <LoadingButton
        startIcon={<OpenAILogoIcon />}
        loading={isLoading}
        disabled={
          hasRole(user, ["admin", "god", "analyst", "manager"]) === false ||
          error !== undefined
        }
        fullWidth
        onClick={() => {
          analyzeListingText({ listing: listing._id });
        }}
        variant="outlined"
      >
        Analyze Listing Text
      </LoadingButton>
    );
  }
};

export const getListingState = (listing) => {
  if (listing.toi === "US") {
    return "UNAUTHORIZED";
  }
  if (listing.state === "DRAFT") {
    return getToi(listing.toi).toUpperCase();
  }
  return getState(listing.state).split(" ")[0].toUpperCase();
};

export const ScoreButton = (props) => {
  const { listing, handleOpenScore, icon } = props;
  return (
    <div>
      {listing?.state === "DRAFT" && (
        <Typography
          variant="overline"
          display="block"
          color="primary"
          sx={{ ml: 2, mt: 5 }}
        >
          * DRAFT *
        </Typography>
      )}

      <Button
        size="large"
        sx={{ width: 150, mt: listing?.state === "DRAFT" ? 0 : 12 }}
        variant="outlined"
        endIcon={icon}
        onClick={() => handleOpenScore(listing)}
      >
        <Typography variant="caption" display="block">
          {getListingState(listing)}
        </Typography>
      </Button>

      {listing?.reason && (
        <Typography variant="caption" display="block" sx={{ mt: 2, ml: 1 }}>
          {getReason(listing.reason)}
        </Typography>
      )}
    </div>
  );
};

export const getRiskIcon = (listing, size) => {
  if (listing.state === "AUTHORIZED_SELLER") {
    return <VerifiedUserIcon sx={{ color: "green", fontSize: size, ml: 5 }} />;
  } else if (listing.toi === "US") {
    return <ShieldIcon sx={{ color: "orange", fontSize: size, ml: 5 }} />;
  } else if (
    (listing.score && listing.score >= 2) ||
    listing.state === "INFRINGING" ||
    (listing.state === "DRAFT" && listing.toi !== undefined)
  ) {
    return <BadShieldIcon sx={{ color: "red", fontSize: size, ml: 5 }} />;
  } else if (listing.state === "GENUINE") {
    return <ShieldIcon sx={{ color: "green", fontSize: size, ml: 5 }} />;
  } else if (listing.state === "LOOKALIKE") {
    return <ShieldIcon sx={{ color: "yellow", fontSize: size, ml: 5 }} />;
  } else if (listing.state === "SELLER_HOLD") {
    return <ShieldIcon sx={{ color: "orange", fontSize: size, ml: 5 }} />;
  } else if (
    (listing.score && listing.score > 0) ||
    listing.state === "SUSPECT"
  ) {
    return <ShieldIcon sx={{ color: "yellow", fontSize: size, ml: 5 }} />;
  }
  return <ShieldOutlinedIcon sx={{ fontSize: size, ml: 5 }} />;
};

export const ListingStatus = (props) => {
  const { status } = props;
  const statusColor = status !== "ACTIVE" ? "red" : "inherit";
  return (
    <Typography component="div" variant="button">
      {"Status: "}
      <span style={{ color: statusColor }}>{getStatus(status)}</span>
    </Typography>
  );
};
